import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
  ...envDef,
  qa: true,
  prefix: 'sindicato-qa',
  version: '1.0.233',
  urlAPI: 'https://sindicato.qa.api.gestor.ladder.dev.br',
  urlBase: 'qa.sindicato.app',
  CrossDomainStorageOrigin: 'https://sindicato.qa.api.gestor.ladder.dev.br',
  firebase: firebaseConfig,
};
